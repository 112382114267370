import React from 'react';
import Signer from './Signer';
import { ApiMySignatureRequestsGet200ResponseSignatureRequestsInner } from '../../generated-client';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './SigningRequests.css';
import { CustomLeftArrow, CustomRightArrow } from './CustomCarouselArrows';

interface SigningRequestsComponentProps {
  signingRequests: ApiMySignatureRequestsGet200ResponseSignatureRequestsInner[] | null;
  handleSign: (data: { id: string; assetHash: string; comment: string }) => void;
}

const SigningRequests: React.FC<SigningRequestsComponentProps> = ({
  signingRequests,
  handleSign,
}) => {
  const requestsToDisplay = signingRequests ?? [];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: Math.min(3, requestsToDisplay.length ?? 1),
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: Math.min(2, requestsToDisplay.length ?? 1),
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {requestsToDisplay ? (
        requestsToDisplay.length > 0 ? (
          <>
            <h2 className="container-title">Signable Assets</h2>
            <div className="carousel-container">
              <Carousel
                responsive={responsive}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
              >
                {requestsToDisplay.map((request) => (
                  <div key={request.id} className="carousel-item">
                    <a
                      href={request.url}
                      target="_blank"
                      rel="noreferrer"
                      className="image-container"
                    >
                      <img
                        className="fixed-width-visual"
                        src={`${process.env.REACT_APP_API_BASE_URL}/api/assets/${request.assetHash}/thumbnail`}
                        alt={`Thumbnail for ${request.id}`}
                      />
                      {request.isSigned && <span className="watermark">Signed</span>}
                      {request.group && <span className="watermark-top">{request.group}</span>}
                    </a>
                    {!request.isSigned && (
                      <Signer
                        id={request.id!}
                        isSigned={request.isSigned!}
                        assetHash={request.assetHash}
                        handleSign={handleSign}
                      />
                    )}
                  </div>
                ))}
              </Carousel>
            </div>
          </>
        ) : (
          <p>No signing requests</p>
        )
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default SigningRequests;
