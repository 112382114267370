import React, { useState } from 'react';
import './BookingConfirmationModal.css';

interface BookingConfirmationModalProps {
  sponsorName: string;
  startTime: Date;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}

const BookingConfirmationModal: React.FC<BookingConfirmationModalProps> = ({
  sponsorName,
  startTime,
  onConfirm,
  onCancel,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleConfirm = async () => {
    setIsProcessing(true);
    try {
      await onConfirm();
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="confirmation-modal">
      <div className="booking-modal-content">
        <h3>Confirm Your Selection</h3>
        <p>You've selected:</p>
        <p>
          <strong>Sponsor:</strong> {sponsorName}
        </p>
        <p>
          <strong>Time:</strong> {startTime.toLocaleString()}
        </p>
        <div className="button-container">
          <button className="confirm-btn" onClick={handleConfirm} disabled={isProcessing}>
            {isProcessing ? 'Processing...' : 'Confirm'}
          </button>
          <button className="cancel-btn" onClick={onCancel} disabled={isProcessing}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmationModal;
