import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService';
interface TabsProps {
  isMenuOpen: boolean;
  userData: { email: string } | null;
  onUserDataChange: (user: { email: string }) => void;
}

const Tabs: React.FC<TabsProps> = ({ isMenuOpen, userData, onUserDataChange }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string | null>(null);

  // Function to check and set the token from localStorage
  const checkAuthToken = async (byStorageEvent: boolean) => {
    try {
      await ApiService.verifyToken(onUserDataChange);
    } catch (error) {
      console.error('Error verifying token', error);
      setEmail(null); // todo: if bySTorageEvent, reload the location to display OTP?
    }
  };

  useEffect(() => {
    if (!userData) {
      checkAuthToken(false);
    } else {
      setEmail(userData.email);
    }
  }, [userData]);

  useEffect(() => {
    // Listen for storage changes
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'jwt') {
        checkAuthToken(true);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <>
      {email && <div className="logged-in-user-info">{email}</div>}
      <div className={`tabs ${isMenuOpen ? 'active' : ''}`}>
        <Link to={APPLY_PATH}>{APPLY_TAB}</Link>
        <Link to={DIRECTORY_PATH} onClick={() => navigate(DIRECTORY_PATH)}>
          {DIRECTORY_TAB}
        </Link>
        <Link to={ABOUT_PATH}>{ABOUT_TAB}</Link>
        <Link to={FAQ_PATH}>{FAQ_TAB}</Link>
        <Link to={NEWS_PATH}>{NEWS_TAB}</Link>
        <Link to={CONTACT_PATH}>{CONTACT_TAB}</Link>
      </div>
    </>
  );
};

export const ABOUT_TAB = 'ABOUT';
export const APPLY_TAB = 'APPLY';
export const DIRECTORY_TAB = 'DIRECTORY';
export const NEWS_TAB = 'NEWS';
export const FAQ_TAB = 'FAQ';
export const CONTACT_TAB = 'CONTACT';
export const ABOUT_PATH = process.env.REACT_APP_ABOUT_URL!;
export const APPLY_PATH = process.env.REACT_APP_APPLY_URL!;
export const DIRECTORY_PATH = '/S';
export const NEWS_PATH = process.env.REACT_APP_NEWS_URL!;
export const FAQ_PATH = process.env.REACT_APP_FAQ_URL!;
export const CONTACT_PATH = process.env.REACT_APP_CONTACT_URL!;
export default Tabs;
