import React from 'react';
import { ExtendedVideo } from '../generated-client';
import './MyVideos.css';
import ApplicationIsComplete from './ApplicationIsComplete';

interface MyVideosComponentProps {
  myVideos: ExtendedVideo[] | null;
  handleSign: (key: string) => void;
}

const MyVideosComponent: React.FC<MyVideosComponentProps> = ({ myVideos, handleSign }) => {
  return (
    <div>
      {myVideos ? (
        myVideos.length > 0 ? (
          myVideos.filter((v) => !v.isSigned).length > 0 ? (
            <>
              <h2 className="container-title">Time to Approve Your Videos</h2>
              <div className="video-row-container">
                {myVideos
                  .filter((v) => !v.isSigned)
                  .map((video, i) => (
                    <div key={video.key || i} className="video-box">
                      <video controls>
                        <source src={video.location} type={video.mediaType} />
                      </video>
                      <p className="video-title">{video.title}</p>
                      <button className="modal-button" onClick={() => handleSign(video.key!)}>
                        Sign
                      </button>
                    </div>
                  ))}
              </div>
            </>
          ) : myVideos.filter((v) => v.isSigned).length >= 2 ? (
            <ApplicationIsComplete />
          ) : (
            <p>No videos to sign</p>
          )
        ) : (
          <p>No videos to sign</p>
        )
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default MyVideosComponent;
