import React, { useEffect, useRef, useState } from 'react';
import ApiService from '../services/ApiService';
import './Submit.css';
import RequestOTP from './RequestOTP';
import { ResponseError } from '../generated-client';
import { useRollbar } from '@rollbar/react';

interface BulkSubmitProps {
  onUserDataChange: (user: { email: string }) => void;
}

interface SubmissionResult {
  url: string;
  message: string;
}

const BulkSubmit: React.FC<BulkSubmitProps> = ({ onUserDataChange }) => {
  const [showRequestOTP, setShowRequestOTP] = useState<boolean>(false);
  const [postUrls, setPostUrls] = useState<string>(''); // Multiline input
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const [currentSubmittingUrl, setCurrentSubmittingUrl] = useState<string | null>(null);
  const [successes, setSuccesses] = useState<SubmissionResult[]>([]);
  const [failures, setFailures] = useState<SubmissionResult[]>([]);
  const isApplyingRef = useRef(isApplying);
  const [error, setError] = useState<string | null>(null);
  const rollbar = useRollbar();

  const handleOTPVerified = ({ email }: { email: string }) => {
    setShowRequestOTP(false);
    setIsApplying(false);
    onUserDataChange({ email });
  };

  useEffect(() => {
    isApplyingRef.current = isApplying;
  }, [isApplying]);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await ApiService.verifyToken(onUserDataChange);
      } catch (error) {
        if (error instanceof ResponseError && error.response.status === 401) {
          setShowRequestOTP(true);
          return;
        } else {
          setError('Network error. Please try again.');
          rollbar.error('Unknown error', error as Error);
        }
      }
    };
    verifyToken();
  }, []);

  const submitPosts = async (urls: string[]) => {
    setSuccesses([]);
    setFailures([]);
    for (let url of urls) {
      setCurrentSubmittingUrl(url);
      try {
        const { success, message } = await ApiService.submitPost(url);
        const resultMessage = message ?? '';
        if (success) {
          setSuccesses((prevSuccesses) => [...prevSuccesses, { url, message: resultMessage }]);
        } else {
          setFailures((prevFailures) => [...prevFailures, { url, message: resultMessage }]);
        }
      } catch (error) {
        setFailures((prevFailures) => [...prevFailures, { url, message: 'Failed to submit!' }]);
      }
    }
    setCurrentSubmittingUrl(null);
    setIsApplying(false);
  };

  const handleRegisterClick = () => {
    setIsApplying(true);
    const urls = postUrls
      .split('\n')
      .map((url) => url.trim())
      .filter((url) => url.length > 0);
    setSuccesses([]);
    setFailures([]);
    submitPosts(urls);
  };

  return (
    <>
      <div className="breadcrumb-trail" />
      {showRequestOTP ? (
        <RequestOTP onOTPVerified={handleOTPVerified} />
      ) : (
        <>
          <h3>
            {isApplying ? <>Submitting the posts...</> : <>Please enter URLs for bulk submission</>}
          </h3>
          <div className="expanded-input-container">
            <div className="expanded-input-row">
              <textarea
                value={postUrls}
                onChange={(e) => setPostUrls(e.target.value)}
                placeholder="Enter each URL on a new line"
                rows={5} // You can adjust the number of rows
                disabled={isApplying} // Disable textarea during submission
              />
              <button className="modal-button" onClick={handleRegisterClick} disabled={isApplying}>
                Submit
              </button>
            </div>

            {isApplying && currentSubmittingUrl && (
              <div>
                <p style={{ color: 'white', fontWeight: 'bold' }}>
                  Submitting {currentSubmittingUrl}...
                </p>
              </div>
            )}

            {error && <p style={{ color: 'red' }}>{error}</p>}

            {successes.length > 0 && (
              <div style={{ color: 'green', textAlign: 'left', marginTop: '20px' }}>
                <strong>The following URLs were successfully submitted for signing:</strong>
                <ul>
                  {successes.map((result, index) => (
                    <li key={index}>
                      <div>{result.url}</div>
                      <div>{result.message}</div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {failures.length > 0 && (
              <div style={{ color: 'red', textAlign: 'left', marginTop: '20px' }}>
                <strong>The following URLs could not be submitted:</strong>
                <ul>
                  {failures.map((result, index) => (
                    <li key={index}>
                      <div>{result.url}</div>
                      <div>{result.message}</div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default BulkSubmit;
