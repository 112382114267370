import React, { useEffect, useRef, useState } from 'react';
import ApiService from '../services/ApiService';
import './Submit.css';
import RequestOTP from './RequestOTP';
import { ResponseError } from '../generated-client';
import { useRollbar } from '@rollbar/react';
interface SubmitProps {
  onUserDataChange: (user: { email: string }) => void;
}

const Submit: React.FC<SubmitProps> = ({ onUserDataChange }) => {
  const [showRequestOTP, setShowRequestOTP] = useState<boolean>(false);
  const [postUrl, setPostUrl] = useState<string>('');
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const isApplyingRef = useRef(isApplying);
  const [error, setError] = useState<string | null>(null);
  const rollbar = useRollbar();

  const handleOTPVerified = ({ email }: { email: string }) => {
    setShowRequestOTP(false);
    setIsApplying(false);
    onUserDataChange({ email });
  };

  useEffect(() => {
    isApplyingRef.current = isApplying;
  }, [isApplying]);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await ApiService.verifyToken(onUserDataChange); // todo: without cookie?
      } catch (error) {
        if (error instanceof ResponseError && error.response.status === 401) {
          setShowRequestOTP(true);
          return;
        } else {
          setError('Network error. Please try again.');
          rollbar.error('Unknown error', error as Error);
        }
      }
    };
    verifyToken();
  }, []);

  useEffect(() => {
    const applyUrl = async () => {
      try {
        let message: string = '';
        if (isApplyingRef.current) {
          const response = await ApiService.submitPost(postUrl);
          message = response.message || '';
        }
        setIsApplying(false);
        setError(null);
        setPostUrl('');
        if (message) {
          alert(message);
        }
      } catch (error) {
        setIsApplying(false);
        if (error instanceof ResponseError && error.response.status === 401) {
          setShowRequestOTP(true);
          return;
        }
        setError('Failed to register the post. Please try again.');
      }
    };
    if (isApplying && postUrl.length > 0) {
      applyUrl();
    }
  }, [isApplying, postUrl]);

  const handleRegisterClick = () => {
    setIsApplying(true);
  };

  return (
    <>
      <div className="breadcrumb-trail" />
      {showRequestOTP ? (
        <RequestOTP onOTPVerified={handleOTPVerified} />
      ) : isApplying ? (
        <div>
          <p>Submitting the post...</p>
        </div>
      ) : (
        <>
          <h3>Please enter a post URL</h3>
          <div className="expanded-input-container">
            <div className="expanded-input-row">
              <input
                type="text"
                value={postUrl}
                onChange={(e) => setPostUrl(e.target.value)}
                placeholder="A tweet or YouTube video URL"
              />
              <button className="modal-button" onClick={handleRegisterClick}>
                Submit
              </button>
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </div>
        </>
      )}
    </>
  );
};

export default Submit;
