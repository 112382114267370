/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAssetsExternalExternalIdGet200Response,
  ApiAssetsExternalExternalIdGet404Response,
  ApiAssetsHashClaimsGet200Response,
  ApiAssetsHashSignaturesGet200Response,
  ApiAvailableslotsGet400Response,
  ApiGrantPost409Response,
  ApiGrantPost500Response,
  ApiGrantPostRequest,
  ApiMySignatureRequestsGet200Response,
  ApiMySignatureRequestsSignPost200Response,
  ApiMySignatureRequestsSignPostRequest,
  ApiMybookingsGet200ResponseInner,
  ApiMybookingsGet500Response,
  ApiMybookingsPost201Response,
  ApiMybookingsPost400Response,
  ApiMybookingsPostRequest,
  ApiMycodeGet200Response,
  ApiMycodeGet401Response,
  ApiMyimagePost200Response,
  ApiMyimagePostRequest,
  ApiMykeyGet200Response,
  ApiMykeyGet401Response,
  ApiMykeyPostRequest,
  ApiMyvideosGet200Response,
  ApiMyvouchingGet200Response,
  ApiMyvouchingGet400Response,
  ApiMyvouchingGet409Response,
  ApiMyvouchingUrlsPost200Response,
  ApiMyvouchingUrlsPost400Response,
  ApiMyvouchingUrlsPostRequest,
  ApiPeopleCodeGet200Response,
  ApiPeopleCodeGet404Response,
  ApiPeopleCodePublicKeysGet200Response,
  ApiPeopleCodePublicKeysGet400Response,
  ApiPeopleCodePublicKeysGet500Response,
  ApiPeopleCodeSocialMediaGet200Response,
  ApiPeopleCodeVideosGet200Response,
  ApiPeopleFirstLettersGet200Response,
  ApiPeopleGet200Response,
  ApiPostPost200Response,
  ApiPostPostRequest,
  ApiSendOtpPostRequest,
  ApiTweetsIdSignaturesGet200ResponseInner,
  ApiVerifyOtpPost200Response,
  ApiVerifyOtpPostRequest,
  AuthVerifyTokenGet200Response,
  AuthVerifyTokenGet401Response,
} from '../models/index';
import {
    ApiAssetsExternalExternalIdGet200ResponseFromJSON,
    ApiAssetsExternalExternalIdGet200ResponseToJSON,
    ApiAssetsExternalExternalIdGet404ResponseFromJSON,
    ApiAssetsExternalExternalIdGet404ResponseToJSON,
    ApiAssetsHashClaimsGet200ResponseFromJSON,
    ApiAssetsHashClaimsGet200ResponseToJSON,
    ApiAssetsHashSignaturesGet200ResponseFromJSON,
    ApiAssetsHashSignaturesGet200ResponseToJSON,
    ApiAvailableslotsGet400ResponseFromJSON,
    ApiAvailableslotsGet400ResponseToJSON,
    ApiGrantPost409ResponseFromJSON,
    ApiGrantPost409ResponseToJSON,
    ApiGrantPost500ResponseFromJSON,
    ApiGrantPost500ResponseToJSON,
    ApiGrantPostRequestFromJSON,
    ApiGrantPostRequestToJSON,
    ApiMySignatureRequestsGet200ResponseFromJSON,
    ApiMySignatureRequestsGet200ResponseToJSON,
    ApiMySignatureRequestsSignPost200ResponseFromJSON,
    ApiMySignatureRequestsSignPost200ResponseToJSON,
    ApiMySignatureRequestsSignPostRequestFromJSON,
    ApiMySignatureRequestsSignPostRequestToJSON,
    ApiMybookingsGet200ResponseInnerFromJSON,
    ApiMybookingsGet200ResponseInnerToJSON,
    ApiMybookingsGet500ResponseFromJSON,
    ApiMybookingsGet500ResponseToJSON,
    ApiMybookingsPost201ResponseFromJSON,
    ApiMybookingsPost201ResponseToJSON,
    ApiMybookingsPost400ResponseFromJSON,
    ApiMybookingsPost400ResponseToJSON,
    ApiMybookingsPostRequestFromJSON,
    ApiMybookingsPostRequestToJSON,
    ApiMycodeGet200ResponseFromJSON,
    ApiMycodeGet200ResponseToJSON,
    ApiMycodeGet401ResponseFromJSON,
    ApiMycodeGet401ResponseToJSON,
    ApiMyimagePost200ResponseFromJSON,
    ApiMyimagePost200ResponseToJSON,
    ApiMyimagePostRequestFromJSON,
    ApiMyimagePostRequestToJSON,
    ApiMykeyGet200ResponseFromJSON,
    ApiMykeyGet200ResponseToJSON,
    ApiMykeyGet401ResponseFromJSON,
    ApiMykeyGet401ResponseToJSON,
    ApiMykeyPostRequestFromJSON,
    ApiMykeyPostRequestToJSON,
    ApiMyvideosGet200ResponseFromJSON,
    ApiMyvideosGet200ResponseToJSON,
    ApiMyvouchingGet200ResponseFromJSON,
    ApiMyvouchingGet200ResponseToJSON,
    ApiMyvouchingGet400ResponseFromJSON,
    ApiMyvouchingGet400ResponseToJSON,
    ApiMyvouchingGet409ResponseFromJSON,
    ApiMyvouchingGet409ResponseToJSON,
    ApiMyvouchingUrlsPost200ResponseFromJSON,
    ApiMyvouchingUrlsPost200ResponseToJSON,
    ApiMyvouchingUrlsPost400ResponseFromJSON,
    ApiMyvouchingUrlsPost400ResponseToJSON,
    ApiMyvouchingUrlsPostRequestFromJSON,
    ApiMyvouchingUrlsPostRequestToJSON,
    ApiPeopleCodeGet200ResponseFromJSON,
    ApiPeopleCodeGet200ResponseToJSON,
    ApiPeopleCodeGet404ResponseFromJSON,
    ApiPeopleCodeGet404ResponseToJSON,
    ApiPeopleCodePublicKeysGet200ResponseFromJSON,
    ApiPeopleCodePublicKeysGet200ResponseToJSON,
    ApiPeopleCodePublicKeysGet400ResponseFromJSON,
    ApiPeopleCodePublicKeysGet400ResponseToJSON,
    ApiPeopleCodePublicKeysGet500ResponseFromJSON,
    ApiPeopleCodePublicKeysGet500ResponseToJSON,
    ApiPeopleCodeSocialMediaGet200ResponseFromJSON,
    ApiPeopleCodeSocialMediaGet200ResponseToJSON,
    ApiPeopleCodeVideosGet200ResponseFromJSON,
    ApiPeopleCodeVideosGet200ResponseToJSON,
    ApiPeopleFirstLettersGet200ResponseFromJSON,
    ApiPeopleFirstLettersGet200ResponseToJSON,
    ApiPeopleGet200ResponseFromJSON,
    ApiPeopleGet200ResponseToJSON,
    ApiPostPost200ResponseFromJSON,
    ApiPostPost200ResponseToJSON,
    ApiPostPostRequestFromJSON,
    ApiPostPostRequestToJSON,
    ApiSendOtpPostRequestFromJSON,
    ApiSendOtpPostRequestToJSON,
    ApiTweetsIdSignaturesGet200ResponseInnerFromJSON,
    ApiTweetsIdSignaturesGet200ResponseInnerToJSON,
    ApiVerifyOtpPost200ResponseFromJSON,
    ApiVerifyOtpPost200ResponseToJSON,
    ApiVerifyOtpPostRequestFromJSON,
    ApiVerifyOtpPostRequestToJSON,
    AuthVerifyTokenGet200ResponseFromJSON,
    AuthVerifyTokenGet200ResponseToJSON,
    AuthVerifyTokenGet401ResponseFromJSON,
    AuthVerifyTokenGet401ResponseToJSON,
} from '../models/index';

export interface ApiAssetsExternalExternalIdGetRequest {
    externalId: string;
}

export interface ApiAssetsHashClaimsGetRequest {
    hash: string;
}

export interface ApiAssetsHashSignaturesGetRequest {
    hash: string;
}

export interface ApiAvailableslotsGetRequest {
    selectedDate: string;
}

export interface ApiGrantPostOperationRequest {
    apiGrantPostRequest: ApiGrantPostRequest;
}

export interface ApiMySignatureRequestsSignPostOperationRequest {
    apiMySignatureRequestsSignPostRequest: ApiMySignatureRequestsSignPostRequest;
}

export interface ApiMybookingsPostOperationRequest {
    apiMybookingsPostRequest: ApiMybookingsPostRequest;
}

export interface ApiMyimagePostOperationRequest {
    apiMyimagePostRequest: ApiMyimagePostRequest;
}

export interface ApiMykeyPostOperationRequest {
    apiMykeyPostRequest: ApiMykeyPostRequest;
}

export interface ApiMyvouchingGetRequest {
    submissionId: string;
}

export interface ApiMyvouchingUrlsPostOperationRequest {
    apiMyvouchingUrlsPostRequest: ApiMyvouchingUrlsPostRequest;
}

export interface ApiPeopleCodeGetRequest {
    code: string;
}

export interface ApiPeopleCodeImageGetRequest {
    code: string;
}

export interface ApiPeopleCodePublicKeysGetRequest {
    code: string;
}

export interface ApiPeopleCodeSocialMediaGetRequest {
    code: string;
}

export interface ApiPeopleCodeVideosGetRequest {
    code: string;
}

export interface ApiPeopleGetRequest {
    search?: string;
}

export interface ApiPostPostOperationRequest {
    apiPostPostRequest: ApiPostPostRequest;
}

export interface ApiSendOtpPostOperationRequest {
    apiSendOtpPostRequest: ApiSendOtpPostRequest;
}

export interface ApiTweetsIdSignaturesGetRequest {
    id: string;
}

export interface ApiVerifyOtpPostOperationRequest {
    apiVerifyOtpPostRequest: ApiVerifyOtpPostRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * This endpoint retrieves the asset URL associated with the specified external ID.
     * Get asset URL and hash by external ID
     */
    async apiAssetsExternalExternalIdGetRaw(requestParameters: ApiAssetsExternalExternalIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAssetsExternalExternalIdGet200Response>> {
        if (requestParameters['externalId'] == null) {
            throw new runtime.RequiredError(
                'externalId',
                'Required parameter "externalId" was null or undefined when calling apiAssetsExternalExternalIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/assets/external/{externalId}`.replace(`{${"externalId"}}`, encodeURIComponent(String(requestParameters['externalId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssetsExternalExternalIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves the asset URL associated with the specified external ID.
     * Get asset URL and hash by external ID
     */
    async apiAssetsExternalExternalIdGet(requestParameters: ApiAssetsExternalExternalIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAssetsExternalExternalIdGet200Response> {
        const response = await this.apiAssetsExternalExternalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves the claims associated with the specified asset hash.
     * Get claims by asset hash
     */
    async apiAssetsHashClaimsGetRaw(requestParameters: ApiAssetsHashClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAssetsHashClaimsGet200Response>> {
        if (requestParameters['hash'] == null) {
            throw new runtime.RequiredError(
                'hash',
                'Required parameter "hash" was null or undefined when calling apiAssetsHashClaimsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/assets/{hash}/claims`.replace(`{${"hash"}}`, encodeURIComponent(String(requestParameters['hash']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssetsHashClaimsGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves the claims associated with the specified asset hash.
     * Get claims by asset hash
     */
    async apiAssetsHashClaimsGet(requestParameters: ApiAssetsHashClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAssetsHashClaimsGet200Response> {
        const response = await this.apiAssetsHashClaimsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves the signatures associated with the specified asset hash.
     * Get signatures by asset hash
     */
    async apiAssetsHashSignaturesGetRaw(requestParameters: ApiAssetsHashSignaturesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAssetsHashSignaturesGet200Response>> {
        if (requestParameters['hash'] == null) {
            throw new runtime.RequiredError(
                'hash',
                'Required parameter "hash" was null or undefined when calling apiAssetsHashSignaturesGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/assets/{hash}/signatures`.replace(`{${"hash"}}`, encodeURIComponent(String(requestParameters['hash']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAssetsHashSignaturesGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves the signatures associated with the specified asset hash.
     * Get signatures by asset hash
     */
    async apiAssetsHashSignaturesGet(requestParameters: ApiAssetsHashSignaturesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAssetsHashSignaturesGet200Response> {
        const response = await this.apiAssetsHashSignaturesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns a list of available time slots for a specific service on a selected date.
     * Fetch available slots for a service
     */
    async apiAvailableslotsGetRaw(requestParameters: ApiAvailableslotsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['selectedDate'] == null) {
            throw new runtime.RequiredError(
                'selectedDate',
                'Required parameter "selectedDate" was null or undefined when calling apiAvailableslotsGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['selectedDate'] != null) {
            queryParameters['selected_date'] = requestParameters['selectedDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/availableslots`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * This endpoint returns a list of available time slots for a specific service on a selected date.
     * Fetch available slots for a service
     */
    async apiAvailableslotsGet(requestParameters: ApiAvailableslotsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.apiAvailableslotsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint applies a grant code to a submission. If the grant code is invalid, an error is returned.
     * Apply grant code
     */
    async apiGrantPostRaw(requestParameters: ApiGrantPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['apiGrantPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiGrantPostRequest',
                'Required parameter "apiGrantPostRequest" was null or undefined when calling apiGrantPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/grant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiGrantPostRequestToJSON(requestParameters['apiGrantPostRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint applies a grant code to a submission. If the grant code is invalid, an error is returned.
     * Apply grant code
     */
    async apiGrantPost(requestParameters: ApiGrantPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrantPostRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve signature requests for the authenticated user
     */
    async apiMySignatureRequestsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMySignatureRequestsGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/my_signature_requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMySignatureRequestsGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve signature requests for the authenticated user
     */
    async apiMySignatureRequestsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMySignatureRequestsGet200Response> {
        const response = await this.apiMySignatureRequestsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Sign an asset for a specific signature request
     */
    async apiMySignatureRequestsSignPostRaw(requestParameters: ApiMySignatureRequestsSignPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMySignatureRequestsSignPost200Response>> {
        if (requestParameters['apiMySignatureRequestsSignPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiMySignatureRequestsSignPostRequest',
                'Required parameter "apiMySignatureRequestsSignPostRequest" was null or undefined when calling apiMySignatureRequestsSignPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/my_signature_requests/sign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiMySignatureRequestsSignPostRequestToJSON(requestParameters['apiMySignatureRequestsSignPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMySignatureRequestsSignPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Sign an asset for a specific signature request
     */
    async apiMySignatureRequestsSignPost(requestParameters: ApiMySignatureRequestsSignPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMySignatureRequestsSignPost200Response> {
        const response = await this.apiMySignatureRequestsSignPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns a list of bookings associated with the authenticated user\'s email.
     * Fetch bookings for the authenticated user
     */
    async apiMybookingsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiMybookingsGet200ResponseInner>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mybookings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiMybookingsGet200ResponseInnerFromJSON));
    }

    /**
     * This endpoint returns a list of bookings associated with the authenticated user\'s email.
     * Fetch bookings for the authenticated user
     */
    async apiMybookingsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiMybookingsGet200ResponseInner>> {
        const response = await this.apiMybookingsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint allows an authenticated user to create a new booking by providing the date in ISO format, along with submissionId and contactId.
     * Create a new booking
     */
    async apiMybookingsPostRaw(requestParameters: ApiMybookingsPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMybookingsPost201Response>> {
        if (requestParameters['apiMybookingsPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiMybookingsPostRequest',
                'Required parameter "apiMybookingsPostRequest" was null or undefined when calling apiMybookingsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/mybookings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiMybookingsPostRequestToJSON(requestParameters['apiMybookingsPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMybookingsPost201ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint allows an authenticated user to create a new booking by providing the date in ISO format, along with submissionId and contactId.
     * Create a new booking
     */
    async apiMybookingsPost(requestParameters: ApiMybookingsPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMybookingsPost201Response> {
        const response = await this.apiMybookingsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves the author code associated with the authenticated user.
     * Get author code
     */
    async apiMycodeGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMycodeGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mycode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMycodeGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves the author code associated with the authenticated user.
     * Get author code
     */
    async apiMycodeGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMycodeGet200Response> {
        const response = await this.apiMycodeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Upload a photo in Base64 format
     */
    async apiMyimagePostRaw(requestParameters: ApiMyimagePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMyimagePost200Response>> {
        if (requestParameters['apiMyimagePostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiMyimagePostRequest',
                'Required parameter "apiMyimagePostRequest" was null or undefined when calling apiMyimagePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/myimage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiMyimagePostRequestToJSON(requestParameters['apiMyimagePostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMyimagePost200ResponseFromJSON(jsonValue));
    }

    /**
     * Upload a photo in Base64 format
     */
    async apiMyimagePost(requestParameters: ApiMyimagePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMyimagePost200Response> {
        const response = await this.apiMyimagePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns the secret key associated with the authenticated user.
     * Get secret key
     */
    async apiMykeyGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMykeyGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/mykey`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMykeyGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns the secret key associated with the authenticated user.
     * Get secret key
     */
    async apiMykeyGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMykeyGet200Response> {
        const response = await this.apiMykeyGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint registers a secret key associated with the authenticated user\'s email.
     * Register secret key
     */
    async apiMykeyPostRaw(requestParameters: ApiMykeyPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['apiMykeyPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiMykeyPostRequest',
                'Required parameter "apiMykeyPostRequest" was null or undefined when calling apiMykeyPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/mykey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiMykeyPostRequestToJSON(requestParameters['apiMykeyPostRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint registers a secret key associated with the authenticated user\'s email.
     * Register secret key
     */
    async apiMykeyPost(requestParameters: ApiMykeyPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiMykeyPostRaw(requestParameters, initOverrides);
    }

    /**
     * This endpoint returns a list of videos associated with the authenticated user.
     * Get user\'s videos
     */
    async apiMyvideosGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMyvideosGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/myvideos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMyvideosGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns a list of videos associated with the authenticated user.
     * Get user\'s videos
     */
    async apiMyvideosGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMyvideosGet200Response> {
        const response = await this.apiMyvideosGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves the vouching summary for a given submission ID. The requester must be the applicant.
     * Get vouching summary
     */
    async apiMyvouchingGetRaw(requestParameters: ApiMyvouchingGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMyvouchingGet200Response>> {
        if (requestParameters['submissionId'] == null) {
            throw new runtime.RequiredError(
                'submissionId',
                'Required parameter "submissionId" was null or undefined when calling apiMyvouchingGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['submissionId'] != null) {
            queryParameters['submissionId'] = requestParameters['submissionId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/myvouching`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMyvouchingGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves the vouching summary for a given submission ID. The requester must be the applicant.
     * Get vouching summary
     */
    async apiMyvouchingGet(requestParameters: ApiMyvouchingGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMyvouchingGet200Response> {
        const response = await this.apiMyvouchingGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint updates the social media URLs for the applicant and their two vouchers associated with a submission ID.
     * Update social media URLs for applicant and vouchers
     */
    async apiMyvouchingUrlsPostRaw(requestParameters: ApiMyvouchingUrlsPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiMyvouchingUrlsPost200Response>> {
        if (requestParameters['apiMyvouchingUrlsPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiMyvouchingUrlsPostRequest',
                'Required parameter "apiMyvouchingUrlsPostRequest" was null or undefined when calling apiMyvouchingUrlsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/myvouching/urls`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiMyvouchingUrlsPostRequestToJSON(requestParameters['apiMyvouchingUrlsPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiMyvouchingUrlsPost200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint updates the social media URLs for the applicant and their two vouchers associated with a submission ID.
     * Update social media URLs for applicant and vouchers
     */
    async apiMyvouchingUrlsPost(requestParameters: ApiMyvouchingUrlsPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiMyvouchingUrlsPost200Response> {
        const response = await this.apiMyvouchingUrlsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns a single person based on the provided unique code.
     * Get a person by their unique code
     */
    async apiPeopleCodeGetRaw(requestParameters: ApiPeopleCodeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPeopleCodeGet200Response>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling apiPeopleCodeGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPeopleCodeGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns a single person based on the provided unique code.
     * Get a person by their unique code
     */
    async apiPeopleCodeGet(requestParameters: ApiPeopleCodeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPeopleCodeGet200Response> {
        const response = await this.apiPeopleCodeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns the image associated with a specific person code.
     * Get image by person code
     */
    async apiPeopleCodeImageGetRaw(requestParameters: ApiPeopleCodeImageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling apiPeopleCodeImageGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people/{code}/image`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * This endpoint returns the image associated with a specific person code.
     * Get image by person code
     */
    async apiPeopleCodeImageGet(requestParameters: ApiPeopleCodeImageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiPeopleCodeImageGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint retrieves the public keys associated with the specified author code.
     * Get public keys by author code
     */
    async apiPeopleCodePublicKeysGetRaw(requestParameters: ApiPeopleCodePublicKeysGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPeopleCodePublicKeysGet200Response>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling apiPeopleCodePublicKeysGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people/{code}/public_keys`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPeopleCodePublicKeysGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint retrieves the public keys associated with the specified author code.
     * Get public keys by author code
     */
    async apiPeopleCodePublicKeysGet(requestParameters: ApiPeopleCodePublicKeysGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPeopleCodePublicKeysGet200Response> {
        const response = await this.apiPeopleCodePublicKeysGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns all social media associated with a specific person code.
     * Get social media accounts by person code
     */
    async apiPeopleCodeSocialMediaGetRaw(requestParameters: ApiPeopleCodeSocialMediaGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPeopleCodeSocialMediaGet200Response>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling apiPeopleCodeSocialMediaGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people/{code}/social_media`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPeopleCodeSocialMediaGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns all social media associated with a specific person code.
     * Get social media accounts by person code
     */
    async apiPeopleCodeSocialMediaGet(requestParameters: ApiPeopleCodeSocialMediaGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPeopleCodeSocialMediaGet200Response> {
        const response = await this.apiPeopleCodeSocialMediaGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns all videos associated with a specific person code.
     * Get videos by person code
     */
    async apiPeopleCodeVideosGetRaw(requestParameters: ApiPeopleCodeVideosGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPeopleCodeVideosGet200Response>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling apiPeopleCodeVideosGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people/{code}/videos`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPeopleCodeVideosGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns all videos associated with a specific person code.
     * Get videos by person code
     */
    async apiPeopleCodeVideosGet(requestParameters: ApiPeopleCodeVideosGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPeopleCodeVideosGet200Response> {
        const response = await this.apiPeopleCodeVideosGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns an array of first letters of last names for which there are records present in the people table.
     * Get first letters of last names
     */
    async apiPeopleFirstLettersGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPeopleFirstLettersGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people/first_letters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPeopleFirstLettersGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns an array of first letters of last names for which there are records present in the people table.
     * Get first letters of last names
     */
    async apiPeopleFirstLettersGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPeopleFirstLettersGet200Response> {
        const response = await this.apiPeopleFirstLettersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns a list of people whose last names start with the given search query.
     * Get people by last name starting with a search query
     */
    async apiPeopleGetRaw(requestParameters: ApiPeopleGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPeopleGet200Response>> {
        const queryParameters: any = {};

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/people`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPeopleGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint returns a list of people whose last names start with the given search query.
     * Get people by last name starting with a search query
     */
    async apiPeopleGet(requestParameters: ApiPeopleGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPeopleGet200Response> {
        const response = await this.apiPeopleGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint registers a post with the specified location for the authenticated user.
     * Register a post
     */
    async apiPostPostRaw(requestParameters: ApiPostPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPostPost200Response>> {
        if (requestParameters['apiPostPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiPostPostRequest',
                'Required parameter "apiPostPostRequest" was null or undefined when calling apiPostPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/post`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiPostPostRequestToJSON(requestParameters['apiPostPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPostPost200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint registers a post with the specified location for the authenticated user.
     * Register a post
     */
    async apiPostPost(requestParameters: ApiPostPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPostPost200Response> {
        const response = await this.apiPostPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint sends a One-Time Password (OTP) to the specified email address.
     * Send OTP to email
     */
    async apiSendOtpPostRaw(requestParameters: ApiSendOtpPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['apiSendOtpPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiSendOtpPostRequest',
                'Required parameter "apiSendOtpPostRequest" was null or undefined when calling apiSendOtpPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/send-otp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiSendOtpPostRequestToJSON(requestParameters['apiSendOtpPostRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint sends a One-Time Password (OTP) to the specified email address.
     * Send OTP to email
     */
    async apiSendOtpPost(requestParameters: ApiSendOtpPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiSendOtpPostRaw(requestParameters, initOverrides);
    }

    /**
     * This endpoint retrieves the assets associated with the specified tweet ID, along with their signatures.
     * Get signatures for assets of a specific tweet
     */
    async apiTweetsIdSignaturesGetRaw(requestParameters: ApiTweetsIdSignaturesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiTweetsIdSignaturesGet200ResponseInner>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiTweetsIdSignaturesGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/tweets/{id}/signatures`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiTweetsIdSignaturesGet200ResponseInnerFromJSON));
    }

    /**
     * This endpoint retrieves the assets associated with the specified tweet ID, along with their signatures.
     * Get signatures for assets of a specific tweet
     */
    async apiTweetsIdSignaturesGet(requestParameters: ApiTweetsIdSignaturesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiTweetsIdSignaturesGet200ResponseInner>> {
        const response = await this.apiTweetsIdSignaturesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint verifies the provided OTP for the specified email address and returns a JSON Web Token (JWT) if successful.
     * Verify OTP and return JWT
     */
    async apiVerifyOtpPostRaw(requestParameters: ApiVerifyOtpPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiVerifyOtpPost200Response>> {
        if (requestParameters['apiVerifyOtpPostRequest'] == null) {
            throw new runtime.RequiredError(
                'apiVerifyOtpPostRequest',
                'Required parameter "apiVerifyOtpPostRequest" was null or undefined when calling apiVerifyOtpPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/verify-otp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiVerifyOtpPostRequestToJSON(requestParameters['apiVerifyOtpPostRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiVerifyOtpPost200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint verifies the provided OTP for the specified email address and returns a JSON Web Token (JWT) if successful.
     * Verify OTP and return JWT
     */
    async apiVerifyOtpPost(requestParameters: ApiVerifyOtpPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiVerifyOtpPost200Response> {
        const response = await this.apiVerifyOtpPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint verifies the provided JWT.
     * Verify JWT
     */
    async authVerifyTokenGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthVerifyTokenGet200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/verify-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthVerifyTokenGet200ResponseFromJSON(jsonValue));
    }

    /**
     * This endpoint verifies the provided JWT.
     * Verify JWT
     */
    async authVerifyTokenGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthVerifyTokenGet200Response> {
        const response = await this.authVerifyTokenGetRaw(initOverrides);
        return await response.value();
    }

}
