import React, { useState, useEffect } from 'react';
import { AvailableTimeslot, ScheduleMeeting, StartTimeEventEmit } from 'react-schedule-meeting';
import ApiService from '../services/ApiService';
import {
  ApiMybookingsGet200ResponseInner,
  ApiMyvouchingGet200Response,
  ResponseError,
} from '../generated-client';
import { formatZohoDate } from '@suremark-digital/suremark-utils';
import BookingConfirmationModal from './BookingConfirmationModal';
import { useRollbar } from '@rollbar/react';

const INTERVIEW_DURATION_IN_MINUTES = 30;

function convertToAvailableTimeslots(isoDates: string[]): AvailableTimeslot[] {
  return isoDates.map((isoDate) => {
    const startTime = new Date(isoDate);
    const endTime = new Date(isoDate);
    endTime.setMinutes(startTime.getMinutes() + INTERVIEW_DURATION_IN_MINUTES);
    return {
      startTime,
      endTime,
    } as AvailableTimeslot;
  });
}

const addDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const ScheduleScreen: React.FC<{
  bookingSummary: ApiMybookingsGet200ResponseInner[];
  onNext: () => void;
  submissionId: string;
  onSubmissionConflict: () => void;
}> = ({ bookingSummary, onNext, submissionId, onSubmissionConflict }) => {
  const [availableSlots, setAvailableSlots] = useState<AvailableTimeslot[]>([]);
  const [selectedDay, setSelectedDay] = useState(addDays(new Date(), 1));
  const [myVouching, setMyVouching] = useState<ApiMyvouchingGet200Response | null>(null);
  const [fetchOk, setFetchOk] = useState<boolean | null>(null);
  const [selectedSponsorId, setSelectedSponsorId] = useState<string>('');
  const [selectedSponsorName, setSelectedSponsorName] = useState<string>('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const rollbar = useRollbar();
  useEffect(() => {
    const fetchMyVouching = async () => {
      try {
        const vouchingData = await ApiService.getMyVouchingSummary(submissionId);
        setMyVouching(vouchingData);
        setSelectedSponsorId(vouchingData.voucher1Id || '');
        setSelectedSponsorName(vouchingData.voucher1Name || '');
        setFetchOk(true);
      } catch (error) {
        if (error instanceof ResponseError && error.response.status === 409) {
          onSubmissionConflict();
          return;
        }
        setFetchOk(false);
        if (error instanceof Error) {
          rollbar.error('Error fetching my vouching data:', error);
        }
      }
    };

    fetchMyVouching();
  }, [submissionId]);

  useEffect(() => {
    const fetchAvailableSlots = async (day: Date) => {
      try {
        const selectedDate = formatZohoDate(day);
        const slots = await ApiService.getAvailableSlots(selectedDate);
        const convertedSlots = convertToAvailableTimeslots(slots);
        setAvailableSlots(convertedSlots);
      } catch (error) {
        if (error instanceof Error) {
          rollbar.error('Error fetching available slots:', error);
        }
      }
    };

    fetchAvailableSlots(selectedDay);
  }, [selectedDay]);

  function handleSelectedDayChange(day: Date): void {
    setSelectedDay(day);
  }

  async function handleTimeSelect(startTimeEventEmit: StartTimeEventEmit) {
    setSelectedTime(startTimeEventEmit.startTime);
    setShowConfirmation(true);
  }

  const handleSponsorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;
    const selectedName = event.target.options[event.target.selectedIndex].text;
    setSelectedSponsorId(selectedId);
    setSelectedSponsorName(selectedName);
  };

  const handleConfirm = async () => {
    if (selectedTime && selectedSponsorId) {
      try {
        await ApiService.postMyBooking(selectedTime, selectedSponsorId, submissionId);
        setShowConfirmation(false);
        alert('Booking successful!');
        onNext();
      } catch (error) {
        if (error instanceof Error) {
          rollbar.error('Error when trying to book:', error);
        }
        setShowConfirmation(false);
        alert('Error when trying to book. Please try again.');
      }
    }
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <div>
      {fetchOk === false && <h2>Submission data could not be extracted</h2>}

      {fetchOk && (
        <>
          <h2>Sponsor {bookingSummary.length + 1} Interview Scheduling</h2>
          <h3>SureMark Digital will send emails with interview times and Zoom links.</h3>
          <h3>
            It is your responsibility to coordinate and schedule your interviews with your sponsors.
          </h3>
          <h3>
            We also suggest you remind them of their video interviews a day or two before the
            appointment.
          </h3>

          {myVouching && (
            <div>
              <label htmlFor="sponsor-select">Select Sponsor:</label>
              <select id="sponsor-select" value={selectedSponsorId} onChange={handleSponsorChange}>
                {!bookingSummary.some((x) => x.sponsorEmail === myVouching.voucher1Email) && (
                  <option value={myVouching.voucher1Id}>{myVouching.voucher1Name}</option>
                )}
                {!bookingSummary.some((x) => x.sponsorEmail === myVouching.voucher2Email) && (
                  <option value={myVouching.voucher2Id}>{myVouching.voucher2Name}</option>
                )}
              </select>
            </div>
          )}

          {showConfirmation && selectedTime ? (
            <BookingConfirmationModal
              sponsorName={selectedSponsorName}
              startTime={selectedTime}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
          ) : (
            <ScheduleMeeting
              availableTimeslots={availableSlots}
              eventDurationInMinutes={INTERVIEW_DURATION_IN_MINUTES}
              onStartTimeSelect={handleTimeSelect}
              onNoFutureTimesAvailable={(selectedDate) => {
                setSelectedDay(selectedDate);
              }}
              onSelectedDayChange={handleSelectedDayChange}
            />
          )}
        </>
      )}
      {fetchOk === null && <h2>Loading...</h2>}
    </div>
  );
};

export default ScheduleScreen;
