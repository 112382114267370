// Custom Left Arrow Component (similar look to default)
export const CustomLeftArrow = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      aria-label="Go to previous slide"
      className="react-multi-carousel-arrow react-multi-carousel-arrow--left"
      onClick={onClick}
      style={{ position: 'absolute', top: '10px', left: '10px' }}
    >
      {/* Unicode character similar to default arrow */}
      &#10094;
    </button>
  );
};

// Custom Right Arrow Component (similar look to default)
export const CustomRightArrow = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      aria-label="Go to next slide"
      className="react-multi-carousel-arrow react-multi-carousel-arrow--right"
      onClick={onClick}
      style={{ position: 'absolute', top: '10px', right: '10px' }}
    >
      {/* Unicode character similar to default arrow */}
      &#10095;
    </button>
  );
};
