import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Link,
} from 'react-router-dom';
import { Provider, ErrorBoundary, useRollbar } from '@rollbar/react'; // Provider imports 'rollbar'
import './App.css';
import Tabs, { DIRECTORY_PATH } from './components/Tabs';
import Directory from './components/Directory';
import ZohoFormEmbed from './components/ZohoFormEmbed';
import ThanksForApplication from './components/ThanksForApplication';
import SignOff from './components/SignOff';
import ProceedToPayment from './components/ProceedToPayment';
import ScheduleAndPhotoForm from './components/ScheduleAndPhotoForm';
import Sign from './components/sign/Sign';
import Submit from './components/Submit';
import BulkSubmit from './components/BulkSubmit';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV || 'testenv',
  autoInstrument: {
    log: true, // Capture console.log
    network: true, // Capture XHR & fetch
    dom: true, // Capture DOM events (e.g., clicks)
    navigation: true, // Capture navigation events
    connectivity: true, // Capture changes in network connectivity
  },
};

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useState<{ email: string } | null>(null); // State to hold user data
  const rollbar = useRollbar(); // Get Rollbar instance

  const handleUserDataChange = (newUserData: { email: string }) => {
    setUserData(newUserData); // Update user data state
    // Set user data in Rollbar context
    rollbar.configure({
      payload: {
        person: {
          id: newUserData.email,
          email: newUserData.email,
        },
      },
    });
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(DIRECTORY_PATH); // make DIRECTORY_TAB the default tab
    } else if (location.pathname.toUpperCase().startsWith(`${DIRECTORY_PATH}/`)) {
      const canonicalPath = location.pathname.toUpperCase();
      if (location.pathname !== canonicalPath) {
        navigate(canonicalPath); // redirect to uppercase path
      }
    }
  }, [location.pathname]);

  return (
    <div className="app">
      <div className="tabs-container">
        <div className="inner-tabs-container">
          <div className="menu">
            <Link to={process.env.REACT_APP_HOME_URL!} className="title">
              <img src="/images/SureMarkLogo.png" alt="SureMark Digital" />
            </Link>
            <div className="hamburger" onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
            <Tabs
              isMenuOpen={isMenuOpen}
              userData={userData}
              onUserDataChange={handleUserDataChange}
            />
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="content">
          <Routes>
            <Route path={`${DIRECTORY_PATH}/:code?`} element={<Directory />} />
            <Route path="/initiate" element={<ZohoFormEmbed />} />
            <Route path="/thank-you" element={<ThanksForApplication />} />
            <Route path="/proceed" element={<ProceedToPayment />} />
            <Route
              path="/schedule"
              element={<ScheduleAndPhotoForm onUserDataChange={handleUserDataChange} />}
            />
            <Route path="/sign-off" element={<SignOff onUserDataChange={handleUserDataChange} />} />
            <Route path="/sign" element={<Sign onUserDataChange={handleUserDataChange} />} />
            <Route
              path="/submit/bulk"
              element={<BulkSubmit onUserDataChange={handleUserDataChange} />}
            />
            <Route path="/submit" element={<Submit onUserDataChange={handleUserDataChange} />} />
          </Routes>
        </div>
        <p className="footer">© 2024 SureMark Digital. All rights reserved.</p>
      </div>
    </div>
  );
};

export default function WrappedApp() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <Router>
          <App />
        </Router>
      </ErrorBoundary>
    </Provider>
  );
}
